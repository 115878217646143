.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  width: 960px;
  margin: 20px auto;
}

.main {
}

.MuiButton-root {
  margin-right: 5px;
}

.player {
  float: left;
  width: 640px;
  height: 360px;
  margin-right: 16px;
}

.right {
  float: right;
  width: 300px;
}
